import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProductAction } from "../../redux/actions/product/getProductAction";
import { productData } from "../../utils/data";
import EditProduct from "./EditProduct";

const ManageProduct = () => {
  const dispatch = useDispatch();
  const abortControllerRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const productsData = useSelector((state) => state.productsData);

  useEffect(() => {
    const abortSignalController = new AbortController();
    abortControllerRef.current = abortSignalController;

    dispatch(
      getProductAction({ value: "", singal: abortSignalController.signal })
    );

    return () => {
      abortSignalController.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (productsData?.data?.data?.length > 0) {
      setProducts(productsData.data.data);
    } else {
      setProducts([]);
    }
  }, [productsData]);

  const handleEditProduct = (rowData) => {
    setOpen(true);
    setEditData(rowData);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);

    // Abort the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    // Dispatch the action with the search value and abort signal
    dispatch(getProductAction({ value, signal: abortController.signal }));
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <button className="mr-2" onClick={() => handleEditProduct(rowData)}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="ml-1 p-1 text-sm font-normal border-1 border-gray-400"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className="main-panel">
        <div className="container-fluid p-3">
          <div className="height-100 bg">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="card caption-top ">
                  <div className="p-3">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6">
                        <h5 className="text-xl">Manage Node</h5>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6 text-end mb-1">
                        <Link to="/addNode">
                          <button className="btn btn-primary">Add Node</button>
                        </Link>
                      </div>
                    </div>
                    <div className="table-card">
                      <DataTable
                        value={products}
                        filters={filters}
                        removableSort
                        tableStyle={{ minWidth: "50rem" }}
                        header={header}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        totalRecords={productData.length}
                        className="bg-none"
                        emptyMessage="No Node Found"
                        showGridlines
                      >
                        <Column
                          className="border-1 text-[14px] p-1 text-center"
                          field="id"
                          header="Sr. No"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center"
                          field="name"
                          header="Node Name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center"
                          field="longitude"
                          header="Longitude"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 text-[14px] p-1 text-center"
                          field="latitude"
                          header="Latitude"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C] text-center"
                          body={actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && <EditProduct setOpen={setOpen} editData={editData} />}
    </>
  );
};

export default ManageProduct;
