import { useParams } from "react-router-dom";
import AlarmsTabs from "./AlarmsTabs";
import DeviceTabs from "./DeviceTabs";
import ModulesTabs from "./ModulesTabs";
import TripsTabs from "./TripsTabs";

const DynamicTab = () => {
  const { tabName } = useParams();

  // useEffect(() => {
  //   dispatch(
  //     allControlPanelShow({
  //       status: tabName === "all" ? "" : `Offline ${capitalized(tabName)}`,
  //       days: 1,
  //     })
  //   );
  // }, [dispatch, tabName]);

  // useEffect(() => {
  //   if (!allCTPData?.loading && allCTPData?.data?.length) {
  //     setAllData(allCTPData?.data);
  //   }
  // }, [allCTPData]);

  return (
    <>
      {/* {tabName === "all" && <AllCTPTabs allCTPData={allData} />} */}
      {tabName === "devices" && <ModulesTabs />}
      {tabName === "modules" && <DeviceTabs />}
      {tabName === "alarms" && <AlarmsTabs />}
      {tabName === "trips" && <TripsTabs />}
    </>
  );
};

export default DynamicTab;
