import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FilterMatchMode } from "primereact/api";
import { categoryData } from "../../utils/data";
import { useDispatch, useSelector } from "react-redux";
import EditCategoryModal from "./EditCategoryModal";
import { getCategoriesData } from "../../redux/actions/categories/getCategoriesData";

const ManageCategory = () => {
  const [products, setProducts] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [EditData, setEditData] = useState([]);

  const categoriesData = useSelector((state) => state.categoriesData);

  useEffect(() => {
    dispatch(getCategoriesData());
  }, [dispatch]);

  useEffect(() => {
    if (categoriesData?.data?.data?.length > 0) {
      setProducts(categoriesData.data.data);
    }
  }, [categoriesData]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleEdit = (rowData) => {
    setEditModal(true);
    setEditData(rowData);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <button className="mr-2" onClick={() => handleEdit(rowData)}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
        {/* <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        /> */}
      </>
    );
  };

  const switchBodyTemplate = (rowdata) => {
    return (
      <>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={rowdata?.status}
            id="flexSwitchCheckDefault"
          />
          <label
            className="form-check-label"
            htmlFor="flexSwitchCheckDefault"
          />
        </div>
      </>
    );
  };

  const template2 = {
    layout: "RowsPerPageDropdown",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 30, value: 30 },
      ];

      return (
        <>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </>
      );
    },
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        {/* <Paginator
          template={template2}
          first={first}
          rows={rows}
          // rows={10}
          // rowsPerPageOptions={[10, 20, 30]}
          totalRecords={data.length}
          onPageChange={(e) => {
            setFirst(e.first);
            setRows(e.rows);
          }}
        /> */}
        <span className="flex items-center">
          <span className="font-normal">Search:</span>
          <InputText
            className="ml-1 p-1 text-sm font-normal border-1 border-gray-400"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className="main-panel">
        <div className="container-fluid py-3">
          <div className="height-100 bg">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <div className="card caption-top ">
                  <div className="p-3">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6">
                        <h5 className="text-xl">Manage Category</h5>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-6 text-end">
                        <Link to="/addCategory">
                          <button className="btn btn-primary">
                            Add Category
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="table-card">
                      <DataTable
                        value={products}
                        filters={filters}
                        removableSort
                        tableStyle={{ minWidth: "50rem" }}
                        header={header}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 20, 30]}
                        totalRecords={categoryData.length}
                        className="bg-none"
                        showGridlines
                      >
                        <Column
                          className="border-1  text-[14px] p-1"
                          field="id"
                          header="Sr. No"
                          sortable
                        ></Column>
                        <Column
                          className="border-1  text-[14px] p-1"
                          field="name"
                          header="Category Name"
                          sortable
                        ></Column>
                        <Column
                          className="border-1 py-2 pl-[2rem]"
                          body={switchBodyTemplate}
                          header="Status"
                        ></Column>
                        <Column
                          className="border-1 py-2 pl-[2.5rem] text-[#FE4C4C]"
                          body={actionBodyTemplate}
                          header="Action"
                        ></Column>
                      </DataTable>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {editModal && (
        <EditCategoryModal setEditModal={setEditModal} EditData={EditData} />
      )}
    </>
  );
};

export default ManageCategory;
